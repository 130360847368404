import React from "react";
import {
  // Customizable Area Start
  Typography,
  Grid,
  Button,
  Box,
  Modal,
  CircularProgress,
  Snackbar,
  Portal,
  styled
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { search, search_icon } from '../../blocks/splashscreen/src/assets'
import MainGeoLocation from "./MainGeoLocation.web";
// import "../../blocks/contentmanagement3/src/scrollbarstyle.css"
import Alert from '@material-ui/lab/Alert';
import { CSSProperties } from "@material-ui/core/styles/withStyles";

const PlaceImgGrid: any = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 150px',
  boxSizing: 'border-box',
  [theme.breakpoints.down('md')]: {
    padding: '0 50px',
  },
}));

const ModalBox: any = styled(Box)(({
  position: 'relative',
  zIndex: 1000,
  margin: '0.5vw auto',
  // width: '58.22vw',
  width: 1118,
  // width:"90%",
  // minHeight: '29vw',
  minHeight: 637,
  //  maxHeight:'46vw',
  // height:"90%",
  border: 'none',
  focus: 'none',
  borderRadius: '1.25vw',
  backgroundColor: '#121212',
  boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  top: 30,
  padding: "49px 24px 32px",
  boxSizing: "border-box",
  "@media(max-width: 900px)": {
    left: "50%",
    transform: "translate(-50%, -10%) scale(.65)",
  },
  "@media(max-width: 600px)": {
    left: "50%",
    transform: "translate(-50%, -30%) scale(.3)",
  }

}));
// Customizable Area End


export interface Props {

  // Customizable Area Start
  openLocation: boolean;
  displayGrid: boolean;
  handleMoreCities: any;
  citiesList: any;
  allCitiesList: any;
  sendCityLocation: any;
  locationAllow: any;
  locationBlock: any;
  geoLocActive: boolean;
  setGeoLoc: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  searchValue: string;
  snackbarOpenFail: boolean;
  alertMsg: string,
  // Customizable Area End
}
let result: any = [];
let resultSearch: any = [];

export default class MainCityLocation extends React.Component<Props, S> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      searchValue: '',
      snackbarOpenFail: false,
      alertMsg: '',
    };
  }


  searchRes = () => {
    resultSearch = this.props.allCitiesList.length > 0 && this.props.allCitiesList.filter((item: any) => {
      const searchTerm: any = this.state.searchValue.toLowerCase();
      const searchCities: any = item?.attributes?.name === null ? "" : item?.attributes?.name.toLowerCase();
      return searchTerm && searchCities.startsWith(searchTerm)
    })

      .slice(0, 10)
    return resultSearch
  }

  handlerSearchChange = (event: any) => {
    this.setState({ searchValue: event.target.value })
  }

  onSearch = (searchText: any) => {
    this.setState({ searchValue: searchText })
    this.props.sendCityLocation(searchText)
    this.setState({ searchValue: "" })

  }

  handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      result = this.props.allCitiesList.filter((item: any) => {
        const searchTerm: any = this.state.searchValue.toLowerCase();
        const searchCities: any = item?.attributes?.name.toLowerCase();
        return (searchTerm == searchCities)

      })

      if (result.length > 0) {

        this.props.sendCityLocation(this.state.searchValue)
        this.setState({ searchValue: '' })
      }
      else if (result.length == 0 || this.state.searchValue == '') {
        this.setState({ snackbarOpenFail: true, alertMsg: 'City not found' });
      }
    }
  }
  handleCloseFail = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbarOpenFail: false })
  }

  render() {
    return <>
      <Portal>
        <Snackbar anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} open={this.state.snackbarOpenFail}
          autoHideDuration={4000}
          onClose={this.handleCloseFail}
        >
          <Alert severity="error">
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
      </Portal>

      {
        this.props.geoLocActive &&
        <MainGeoLocation locationAllow={this.props.locationAllow} locationBlock={this.props.locationBlock} />
      }

      <Modal
        open={this.props.openLocation}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        BackdropProps={{ style: { backgroundColor: 'rgba(45,45,45,0.6)' } }}
      >
        <ModalBox>

          {/* ----------------------- search box ------------------ */}
          <Box style={webStyle.searchBoxStyle}>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={search}
                style={webStyle.searchbarIconStyle}
                alt='searchbar icon'
              />

              <input type="text"
                name='search'
                placeholder='Search for your city'
                value={this.state.searchValue}
                onChange={this.handlerSearchChange}
                onKeyDown={this.handleKeyDown}
                style={webStyle.searchInputStyle}
                data-testid="searchTextLoc"
              />

            </Box>

            <img
              src={search_icon}
              style={webStyle.searchIconStyle}
              alt='search icon'
              onClick={() => {
                this.setState({ searchValue: '' })
                this.props.setGeoLoc()
              }
              }
            />

          </Box>
          <div style={webStyle.dropDown}>
            {

              this.searchRes().length > 0 && this.searchRes().map((item: any) => {

                return (

                  <div
                    key={item.id}
                    onClick={() => { this.onSearch(item?.attributes?.name) }}
                    style={webStyle.dropdownRow}>
                    <p style={{ marginLeft: "0.5vw" }}>{item?.attributes?.name}</p>
                  </div>

                )
              })

            }
            {
              resultSearch.length == 0 && this.state.searchValue != '' &&
              <div
                style={webStyle.dropdownRow}>
                <p style={webStyle.errorSearchStyle}>No city found</p>
              </div>
            }


          </div>

          <Typography style={webStyle.modalTextTitle}>Popular Cities</Typography>

          <PlaceImgGrid container>
            {
              this.props.citiesList.length > 0 ?
                this.props.citiesList.map((item: any) => {
                  return (
                    <>
                      <Grid item key={item.id} style={{ flexDirection: 'column', boxSizing: 'border-box', ...webStyle.placeImgGridItem }}
                        onClick={() => { this.props.sendCityLocation(item?.attributes?.name) }}
                      >
                        <img
                          src={item?.attributes?.image}
                          style={webStyle.cityIconStyle}
                          alt='city icon'
                        />
                        <Typography style={webStyle.placeImgGridText}>{item?.attributes?.name}</Typography>
                      </Grid>
                    </>
                  )
                })
                :
                <CircularProgress />
            }


          </PlaceImgGrid>

          {/* ------------------------------- List of Cities ---------------------------------- */}

          <Box style={{ display: this.props.displayGrid ? 'flex' : 'none', ...webStyle.listCitiesBox }}>
            <Typography align='left' style={webStyle.listCitiesTitle}>List of all Cities</Typography>

            <div className=".myscroll" style={{ height: "20vh" }}>

              <Grid container style={webStyle.listCitiesGridContainer}>
                {
                  this.props.allCitiesList.length > 0 ?
                    this.props.allCitiesList.map((item: any) => {
                      return (
                        <>
                          <Grid item key={item?.id} xs={3} >
                            <Typography align='left'
                              style={webStyle.listCitiesText}
                              onClick={() => { this.props.sendCityLocation(item?.attributes?.name) }}
                            >
                              {item?.attributes?.name}</Typography>

                          </Grid>

                        </>
                      )
                    })
                    :
                    <Box style={webStyle.loaderStyle}>
                      <CircularProgress />
                    </Box>
                }

              </Grid>
            </div>

          </Box>

          {/* -------------------------------- More Cities Button --------------------------- */}
          <Button variant='contained'
            style={webStyle.modalBtnAllCities}
            onClick={this.props.handleMoreCities}
            data-test-id="more_cities"
          >
            {
              !this.props.displayGrid ? 'View all Cities' : 'Hide List'
            }
          </Button>
        </ModalBox>

      </Modal>

    </>
  }
}

// Customizable Area Start
const webStyle = {
  modalStyle: {
    position: 'relative' as const,
    zIndex: 1000,
    margin: '0.5vw auto',
    // width: '58.22vw',
    maxWidth: 1118,
    width: "90%",
    // minHeight: '29vw',
    minHeight: 637,
    //  maxHeight:'46vw',
    // height:"90%",
    border: 'none',
    focus: 'none',
    borderRadius: '1.25vw',
    backgroundColor: '#121212',
    boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column' as const,
    top: 30,
    padding: "49px 24px 32px",
    boxSizing: "border-box",
  } as CSSProperties,

  searchBoxStyle: {
    // marginTop:'1.2vw',
    // height:'2.7vw', 
    // width:'55.7vw' ,
    width: "100%",
    height: 53,
    borderRadius: '0.2vw',
    backgroundColor: '#404040',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // padding:'0.5vw 0.7vw 0.5vw 1.25vw', 
    padding: "0px 24px",
    boxSizing: 'border-box' as const,
  },
  searchInputStyle: {
    outline: 'none',
    lineHeight: "1.25",
    border: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    fontSize: 16,
    fontFamily: "Poppins",
    color: '#fff',
  },

  searchbarIconStyle: {
    // height:'1.5vw',
    // width:'1.5vw',
    height: 24,
    width: 24,
    // margin:'0.2vw 0.7vw 0.2vw 0'
    marginRight: 8
  },
  searchIconStyle: {
    // height:'1.5vw',
    // width:'1.5vw',
    height: 32,
    width: 32,
  },
  modalTextTitle: {
    color: '#fff',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 600,
    // marginTop:'1.5vw',
    marginTop: 50,
    marginBottom: 32,
  },

  modalBtnAllCities: {
    marginTop: '1.5vw',
    marginBottom: '1.2vw',
    width: '18vw',
    height: '2.5vw',
    borderRadius: '0.6vw',
    backgroundColor: '#ffb43a',
    fontFamily: 'Poppins',
    color: ' #202020',
    fontSize: '1vw',
    fontWeight: 600,
    padding: '0.6vw 0.7vw',
    boxSizing: 'border-box' as const,
    textTransform: 'none' as const,
  },
  placeImgGrid: {

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 9vw',
  },

  placeImgGridItem: {
    // margin:'0.7vw 0.4vw',
    // padding:'1vw 1.1vw 1vw',
    padding: '0.5vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#373737',
    borderRadius: '0.4vw',
    maxHeight: '120px',
    height: "12vh",
    width: '104px',
    cursor:"pointer",
    // width:"10vw",
    margin: "10px",
    // height:'6.25vw',
    // width:'5.4vw'
    // padding:'0.9vw 1vw 0.9vw',

  },
  placeImgGridText: {
    fontFamily: 'Poppins',
    color: '#fff',
    // fontSize: '0.8vw',
    fontSize: 12,
    marginTop: '0.4vw'
  },

  cityIconStyle: {
    // width:'2.5vw',
    // height:"2.8vw",
    width: '65px',
    height: "49px",
  },
  listCitiesTitle: {
    fontFamily: 'Poppins',
    color: '#fff',
    // fontSize: '1.25vw',
    fontSize: '24px',
    fontWeight: 600,
    // marginBottom:'1.25vw'
    marginBottom: 10,
  },
  listCitiesText: {
    fontFamily: 'Poppins',
    color: '#fff',
    // fontSize: '0.9vw',
    fontSize: '18px',
    cursor: 'pointer',
    padding: "10px 0"
  },

  lineStyle: {
    height: '1px',
    backgroundColor: 'rgb(204, 204, 204, 0.5)',
    border: 'none',
    width: '100%',
  },
  listCitiesBox: {
    width: '92%',
    justifyContent: 'center',
    flexDirection: 'column' as const,
  },
  listCitiesGridContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 'wrap',
    overflowY: 'auto' as const,
    // height:'6.9vw',
    maxHeight: "220px",
    height: "100%",
  },

  dropDown: {
    position: 'absolute' as const,
    // top:'4.2vw',
    top: 103,
    backgroundColor: '#252525',
    boxSizing: 'border-box' as const,
    cursor: 'pointer',
    width: '95%',
    display: 'flex',
    flexDirection: 'column' as const,
    fontFamily: 'Poppins',
    color: ' #fff',
    // fontSize: '0.7vw',
    fontSize: 14,
    borderBottomRightRadius: '0.9vw',
    borderBottomLeftRadius: '0.9vw',
    zIndex: 100
  },

  dropdownRow: {
    cursor: 'pointer',
    //  padding:'0.3vw',
    borderBottom: '1px solid #000'
  },

  input: {
    width: '11.45vw',
    // width:'220px',

  },
  searchInner: {
    display: 'flex'
  },
  loaderStyle: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  errorSearchStyle: {
    fontFamily: 'Poppins',
    color: '#ff3b30',
    fontSize: '0.7vw',
    marginLeft: '1.4vw',
  },
}

// Customizable Area End