import React from "react";

// Customizable Area Start

import {
    Box,
    Button,
    Container,
    Grid,
    Paper,
    Typography,
    Divider,
    IconButton,
    Fade,
    CircularProgress,
    Tooltip,
} from "@material-ui/core";

import { createTheme, styled, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { AddFavourites } from "../../favourites/src/AddFavourites.web";
import Share from "../../share/src/Share.web";
import ListWithToggle from "../../../components/src/ListWithToggle.web";
import { btnPlay, filled_heart } from "../../reservations2/src/assets";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import { location, share, whiteHeart } from "./assets";
import { ArrowBackIosOutlined } from "@material-ui/icons";
import { SelectPreference } from "./SelectPreference.web";
import { convertToPad, getFormattedDate, goToPrevPage } from "../../../components/src/utilities";
import UserSignUp from "../../social-media-account-registration/src/UserSignUp.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffb43a",
        },
        background: {
            paper: "#121212",
            default: "#404040",
        },
        text: {
            primary: "#FFF",
        },
    },
    typography: {
        fontFamily: "Poppins",
        h1: {
            fontSize: '24px',
            fontWeight: 600
        },
        h2: {
            fontSize: 18,
            fontWeight: "normal",
            lineHeight: 1.78,
            margin: "15px 0px"
        },
        h3: {
            fontSize: 16,
            fontWeight: 600
        },
        h4: {
            fontSize: 16,
            fontWeight: "normal",
            '@media(max-width:600px)': {
                fontSize: "11px",
            },
        },
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
    overrides: {
        MuiTooltip: {
          tooltip: {
            backgroundColor: "#121212",
            color: "#b4b4b4",
            border: "solid 1px #b4b4b4",
            position: 'relative',
            fontFamily: "Poppins"
          },
          arrow: {
            '&:before': {
              border: 'solid 1px #b4b4b4',
            },
            color: '#121212',
          },
        }
      }
});

type ArtistDetails = {
    image_url: string,
    name: string
}

const IconWrapperBox = styled(Box)({
    gap: "12px",
    display: "flex",
    alignItems: "center",
    "& img": {
      margin: "0 !important",
      width: "24px !important",
      height: "24px !important",
    }
  })

const BookTicketsBtn = styled(Button)({
    fontSize: "16px",
    color: "#000",
    textTransform: 'capitalize',
    fontWeight: 600,
    fontFamily: "Poppins",
    border: "none",
    borderRadius: "12px",
    cursor: "pointer",
    backgroundColor: "#ffb43a",
    marginRight: "24px",
    padding: "16px",
    minWidth: "200px",
    "&:hover": {
        backgroundColor: "#ffb43a",
        color: "#000",
    },
    '@media(max-width:600px)': {
        marginRight: 0,
    },
})

// Customizable Area End

import EventDetailScreenController, {
    Props,
} from "./EventDetailScreenController";

class EventDetailScreen extends EventDetailScreenController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderItoTimer () {
        const { classes } = this.props;
        return(
             <Box>
                <Box className={classes.countdownWrapper}>

                    <Typography variant='h6' align='center'>Closes in</Typography>

                    <Box className='timer-number-wrapper'>
                        <Box>
                            <Typography className='numbers' align='center'>{this.state.days}</Typography>
                            <Typography className='time-text'>Days</Typography>
                        </Box>

                        <Divider />

                        <Box>
                            <Typography className='numbers' align='center'>{convertToPad(this.state.hours)}</Typography>
                            <Typography className='time-text'>Hours</Typography>
                        </Box>

                        <Divider />

                        <Box>
                            <Typography className='numbers' align='center'>{convertToPad(this.state.minutes)}</Typography>
                            <Typography className='time-text'>Minutes</Typography>
                        </Box>

                        <Divider />

                        <Box>
                            <Typography className='numbers' align='center'>{convertToPad(this.state.seconds)}</Typography>
                            <Typography className='time-text'>Seconds</Typography>
                        </Box>
                    </Box>

                </Box>
            </Box>
        )
    }

    renderScheduleDate() {
        const { eventData: { attributes: { classify, schedules }} } = this.state

        if(classify === 'recurring') {
            const startDate = schedules[schedules.length - 1].schedule_date;
            const endDate = schedules[0].schedule_date;

            if(startDate == endDate) return getFormattedDate(schedules[0].schedule_date, "MMM D ddd, YYYY")
            return `${getFormattedDate(startDate, "MMM D ddd, YYYY")} - ${getFormattedDate(endDate, "MMM D ddd, YYYY")}` 

        }

        return getFormattedDate(schedules[0].schedule_date, "MMM D ddd, YYYY")
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const shareAttributies = {
            shareSrc: share
            ,
            imageStyle: webStyle.imgShareStyles
        }



        const addFavourites = {
            heartIcon: whiteHeart,
            filledHeart: filled_heart,
            imageStyle: webStyle.imgShareStyles,
            favouriteable_id: this.state.eventData.id,
            favouriteable_type: "BxBlockEvents::EventDetail",
            tooltipDirection: "bottom",
            isFavourite: this.state.eventData.attributes?.is_favourite
        }


        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Paper elevation={0} >
                    {this.state.isLoading ? <Fade in={this.state.isLoading} unmountOnExit>
                        <Box display="flex" justifyContent="center">
                            <CircularProgress style={webStyle.CircularProgress} />
                        </Box>
                    </Fade> : <>
                        {/* For Web design */}
                        <Box display={{ xs: "none", md: "block" }}>
                            <MainHeaderComp navigation={this.props.navigation} />
                            <Box sx={webStyle.HeroWrapper}>
                                <Paper elevation={0} style={{
                                    backgroundImage: `linear-gradient(90deg, rgb(26, 26, 26) 45%, rgb(26, 26, 26) 38.3%, rgba(26, 26, 26, 0.04) 97.47%, rgb(26, 26, 26) 100%), url(${this.state.eventData.attributes?.poster_url[0]})`,
                                    ...webStyle.imgPaperStyle
                                }}>
                                </Paper>

                                <Box sx={webStyle.SectionWrapper}>
                                    <Container>
                                        <Grid container>
                                            <Grid item xs={7}>

                                                <Box style={webStyle.bannerContent}>
                                                    <Typography id="movieName" style={webStyle.contentMovieName}>{this.state.eventData.attributes?.name}</Typography>
                                                    <Box display="flex" flexWrap="wrap" gridGap="16px" gridRowGap="8px">
                                                        {this.state.eventData.attributes?.show_type.map((elm: string) => <Typography style={webStyle.contentMovieGenre}>{elm} </Typography>)}
                                                    </Box>
                                                    <Typography style={webStyle.contentMovieDuration}>{this.state.eventData.attributes?.above_age}+&nbsp;&nbsp;&nbsp;Duration:&nbsp;<span style={webStyle.contentMovieDuration}>
                                                        1h 38m</span></Typography>
                                                    <Box mt={2} mb={2}>
                                                        <Typography>
                                                            
                                                            {
                                                                <ListWithToggle itemsList={this.state.eventData.attributes?.languages.map((item: any) => item.name)} />
                                                            }
                                                        </Typography>
                                                    </Box>

                                                    <Typography variant="h4">
                                                        {this.renderScheduleDate()}
                                                    </Typography>

                                                    <Typography variant="h4" style={webStyle.locationContainer}>
                                                        <img src={location} style={webStyle.locationIcon} />
                                                        {this.state.eventData.attributes?.venues[0].full_address}
                                                    </Typography>

                                                    {this.state.eventData.attributes?.venues.length > 1 && <Box data-test-id="more_venue" onClick={() => this.handleOnBookTicket()} className={classes.moreVenue}>+{this.state.eventData.attributes?.venues.length - 1} More Venues</Box>}

                                                        {this.state.ITOlist && this.renderItoTimer()}
                                                    <Box style={{ marginTop: '32px', alignItems: 'center', ...webStyle.displayRowStyle }}>
                                                        {
                                                            this.state.ITOlist ?
                                                                <BookTicketsBtn data-test-id="BookTicketBtn1"
                                                                    onClick={() => this.handleOnBookTicket()}
                                                                    disabled = {this.state.isExpired}
                                                                >
                                                                    Apply for ITO
                                                                </BookTicketsBtn> :
                                                                <BookTicketsBtn data-test-id="BookTicketBtnnormal"
                                                                    onClick={() => this.handleOnBookTicket()}
                                                                    disabled={this.state.showTimeOver}
                                                                >
                                                                    Book Tickets
                                                                </BookTicketsBtn>
                                                        }
                                                        {(this.state.eventData.attributes?.video_url.length > 0 || this.state.eventData.attributes?.youtube_links.length > 0) && <Tooltip
                                                            title="Play Trailer" arrow>
                                                            <img
                                                                src={btnPlay}
                                                                style={webStyle.imgShareStyles}
                                                                onClick={this.navigateToTrailer}
                                                                data-test-id="trailerBtn"
                                                            />
                                                        </Tooltip>
                                                        }

                                                        <AddFavourites addFavourites={addFavourites} navigation={""} id={""} />

                                                        <Share shareDetails={shareAttributies} navigation={''} id={""} />
                                                    </Box>

                                                </Box>

                                            </Grid>
                                            <Grid item xs={5}>

                                                <Box style={webStyle.bannerImage}>
                                                    <img
                                                        src={this.state.eventData.attributes?.poster_url[0]}
                                                        style={webStyle.bannerMovieImg}
                                                    />
                                                </Box>

                                            </Grid>
                                        </Grid>
                                    </Container>
                                </Box>

                            </Box>
                        </Box>

                        {/* For Mobile design */}
                        <Box display={{ xs: "block", md: "none" }}>

                            <Container style={webStyle.MobileHeader}>
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <IconButton style={webStyle.BackButton}  onClick={goToPrevPage}
                                    >
                                        <ArrowBackIosOutlined style={webStyle.BackButtonIcon} />
                                    </IconButton>

                                    <Box display="flex" gridGap="17px">

                                        <IconWrapperBox>

                                            <AddFavourites addFavourites={addFavourites} navigation={""} id={""} />
                                            <Share shareDetails={shareAttributies} navigation={''} id={""} />
                                        
                                        </IconWrapperBox>

                                    </Box>
                                </Box>
                            </Container>

                            <Box position="relative" mb={2}>
                                <img src={this.state.eventData.attributes?.poster_url[0]} alt="poster-image" style={webStyle.Poster} />

                                <Box style={webStyle.BackDrop}></Box>
                            </Box>

                            <Container>
                                <Box display="flex" gridGap="14px" mb={3}>

                                    <Box style={webStyle.PosterImage}>
                                        <img src={this.state.eventData.attributes?.poster_url[0]} alt="poster-image" style={webStyle.ThumbnailPoster} />
                                    </Box>

                                    <Box>
                                        <Typography style={webStyle.Title}>{this.state.eventData.attributes?.name}</Typography>
                                        <Box display="flex" gridGap="8px" gridRowGap="8px" mt={1.5} mb={1.5} flexWrap="wrap" maxWidth="185px">

                                        {this.state.eventData.attributes?.show_type.map((elment: string) => <Typography style={webStyle.MovieTag}>{elment} </Typography>)}
                                        </Box>
                                        <Typography style={webStyle.Duration}>{this.state.eventData.attributes?.above_age}+ Duration : 1h 38m</Typography>
                                        <Typography style={webStyle.LanguageFormat}>
                                            <span>
                                                <ListWithToggle itemsList={this.state.eventData.attributes?.languages.map((item: any) => item.name)} />
                                            </span>
                                        </Typography>
                                        <Typography variant="h4">
                                            {getFormattedDate(this.state.eventData.attributes?.schedules[0]?.schedule_date, "MMM D ddd, YYYY")} -
                                            {getFormattedDate(this.state.eventData.attributes?.schedules[this.state.eventData.attributes?.schedules.length - 1]?.schedule_date, "MMM D ddd, YYYY")}
                                        </Typography>

                                        <Typography variant="h4" style={webStyle.locationContainer}>
                                            <img src={location} style={webStyle.locationIcon} />
                                            {this.state.eventData.attributes?.venues[0].name}
                                        </Typography>

                                        {this.state.eventData.attributes?.venues.length > 1 && <Box data-test-id="more_venue" className={classes.moreVenue} onClick={() => this.handleOnBookTicket()}>+{this.state.eventData.attributes?.venues.length - 1} More Venues</Box>}
                                    </Box>

                                </Box>
                            </Container>

                            <Container>

                                {this.state.ITOlist && this.renderItoTimer()}
                                <Box textAlign="center">
                                    <BookTicketsBtn
                                        data-test-id="BookTicketBtn"
                                        onClick={() => this.handleOnBookTicket()}
                                        style={webStyle.BtnMaxHeight}>
                                          {this.state.ITOlist ? "Apply for ITO": "Book Tickets"}
                                    </BookTicketsBtn>
                                </Box>
                            </Container>

                        </Box>

                        <Container>
                            <Box style={webStyle.movieDescBoxStyle}>
                                <Typography style={{ fontSize: '24px', fontWeight: 600 }}>Event Description</Typography>
                                <Typography style={{ marginTop: '16px', fontSize: '18px' }}>{this.state.eventData.attributes?.description}</Typography>
                            </Box>
                            <hr style={webStyle.lineStyle} />

                            <Box style={webStyle.SectionTitle}>
                                <Typography variant="h1">Artist</Typography>
                            </Box>
                            <Box className={classes.displayFlex}>
                                    {this.state.eventData.attributes?.event_artist.map((item: ArtistDetails) => (
                                        <Box>
                                            <img style={webStyle.artistImage} src={item.image_url} />
                                            <Typography variant="h3">{item.name}</Typography>
                                        </Box>
                                    ))}
                            </Box>
                            <hr style={webStyle.lineStyle} />

                            <Box className={classes.eTicketBox}>
                                <Typography variant="h1">E-Ticket Information</Typography>
                                <Typography variant="h2">1. Customers can access their E-Ticket from Email.
                                    <br />2. It is mandatory to present E-ticket at the venue.</Typography>
                            </Box>
                        </Container></>}

                    <FooterWithEvents />
                </Paper>

                {this.state.isSingleVenue && (
                    <SelectPreference
                        data-test-id="closePreferenace"
                        classes={classes}
                        navigation={""}
                        handleOnClose={this.onClosePreference}
                        handleOnSelectRegularBooking={this.onSelectRegularBooking}
                        handleOnSelectListedTicket={this.onSelectListedTicket}
                        id=""
                        sheduled_id={this.state.eventData.attributes?.schedules[0].id}
                    />
                )}

                {this.state.openSignUp && <UserSignUp navigation={this.props.navigation} id="user-signup" showSignup={this.closeSignUp} />}
                
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const styles = {
    eTicketBox: {
        maxWidth: "485px",
        width: "100%",
        padding: "20px 10px",
        borderRadius: "8px",
        backgroundColor: "#2f2617",
        boxSizing:"border-box" as const
    },
    Dividers: {
        margin: "24px 0",
        backgroundColor: "#979797",
    },
    CloseIcons: {
        display: "flex",
        alignItems: "flex-end",
        padding: 5,
        width: '28px',
        height: '28px',
        cursor: 'pointer',
    },
    moreVenue: {
        color: "#ffb636",
        fontSize: "16px",
        fontWeight: "normal" as const,
        fontFamily: "Poppins",
        marginTop: 15,
        cursor: "pointer",
        '@media(max-width:600px)': {
            fontSize: "11px",
        },
    },
    prefMainBox: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap" as const,
        padding: "0 50px",
        '@media(max-width:600px)': {
            padding: "0"
        },
    },
    dialogContent:{
        '@media(max-width:600px)': {
            padding: "0px"
        },
    },
    displayFlex: {
        display: "flex",
        gap: "20px",
        overflowX: "scroll" as const,
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
    },
    countdownWrapper: {
        border: "solid 1px #fff",
        backgroundColor: "#2f2617",
        fontFamily: "Poppins",
        padding: "18px 20px 20px",
        borderRadius: "8px",
        margin: "15px 0 0px",
        width: "390px",
        "& .timer-number-wrapper": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "12px",
          "& .numbers": {
            fontSize: "20px",
            fontWeight: 600,
            color: "#ffb43a",
          },
          "& .time-text": {
            fontSize: "14px",
            color: "#fff"
          },
          "& hr": {
            backgroundColor: "#8a8a8a",
            height: "24px",
            width: "1px"
          }
        },
        '@media(max-width:600px)': {
            width:"100%",
            boxSizing:"border-box",
            marginBottom:30
        },
      },
}

const webStyle = {
    HeroWrapper: {
        position: 'relative',
        height: "640px",
        overflow: 'hidden'
    },
    CircularProgress: {
        marginTop: "20px",
        color: "#ffb43a"
    },
    imgPaperStyle: {
        content: '""',
        position: 'absolute' as const,
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        zIndex: 1,
        filter: "blur(8px)",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    SectionWrapper: {
        position: 'absolute' as const,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 2
    },
    bannerContent: {
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column' as const,
    },
    fontStyle: {
        fontFamily: 'Poppins', color: '#fff'
    },
    contentMovieName: {
        fontSize: '32px',
        marginBottom: '22px',
        marginTop: '68px',
        fontWeight: 500
    },
    contentMovieGenre: {
        fontSize: '16px',
        border: 'solid 0.5px #ffb53a',
        borderRadius: '1px',
        padding: '8px',
        boxSizing: 'border-box' as const,
        textTransform: "capitalize" as const
    },
    displayRowStyle: {
        display: 'flex',
    },
    bannerImage: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: "35px"
    },
    bannerMovieImg: {
        width: '386px',
        height: '528px',
    },
    imgShareStyles: {
        height: '32px',
        width: '32px',
        marginRight: '25px',
        cursor: "pointer"
    },
    contentMovieDuration: {
        fontSize: '16px',
        marginTop: '16px',
        fontWeight: 300,
    },
    MobileHeader: {
        paddingTop: "12px",
        paddingBottom: "12px"
    },
    BackButton: {
        width: "24px",
        height: "24px",
        backgroundColor: "#ffb43a"
    },
    IconWrapper: {
        height: "24px",
        width: "24px",
    },
    BackButtonIcon: {
        width: "16px",
        height: "16px",
    },
    BackDrop: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        opacity: 0.4,
        backgroundColor: "grey",
        position: "absolute" as const,
    },
    PosterImage: {
        height: "158px",
        width: "145px",
        overflow: "hidden",
        border: "solid 1px #ffb43a",
        boxSizing:"border-box" as const,
    },
    ThumbnailPoster: {
        width: "100%",
        height: "99%",
    },
    Title: {
        fontSize: "16px",
        fontWeight: 600,
        color: "#fff",
        fontFamily: "Poppins, sans-serif",
    },
    Duration: {
        color: "#fff",
        fontSize: "12px",
        fontWeight: 200,
        fontFamily: "Poppins, sans-serif",
    },
    TextTransform: {
        textTransform: "uppercase" as const,
    },
    MovieTag: {
        color: "#fff",
        fontSize: "12px",
        padding: "4px 8px",
        borderRadius: "1px",
        border: "solid 0.5px #ffb43a",
        fontFamily: "Poppins, sans-serif",
        textTransform: "capitalize" as const,
    },
    LanguageFormat: {
        fontFamily: "Poppins, sans-serif",
        textTransform: "uppercase" as const,
        color: "#fff",
        fontSize: "10px",
        maxWidth: "185px",
        textWrap: "wrap",
        marginTop: "4px",
        marginBottom: "4px"
    },
    LanguageFormatSpacing: {
        marginRight: "24px",
    },
    ReleaseDate: {
        color: "#fff",
        fontSize: "11px",
        padding: "4px 8px",
        borderRadius: "4px",
        marginTop: "8px",
        backgroundColor: "#2f2617",
        border: "solid 0.5px #fff",
        display: "inline-block" as const,
    },
    movieDescBoxStyle: {
        marginTop: '32px'
    },
    lineStyle: {
        height: '1px',
        border: 'none',
        margin: '32px 0',
        backgroundColor: '#404040',
    },
    SectionTitle: {
        marginBottom: "12px"
    },
    Poster: {
        height: "211px",
        width: "100%",
    },
    BtnMaxHeight: {
        maxHeight: "48px"
    },
    locationIcon: {
        width: 18,
        height: 22,
        marginRight: 10
    },
    locationContainer: {
        display: "flex",
        alignItems: "center",
        marginTop: 15,
    },
    artistImage: {
        width: 154,
        height: 154,
        marginBottom: "16px"
    }
}

export default (withStyles(styles)(EventDetailScreen))
export { EventDetailScreen }
// Customizable Area End
