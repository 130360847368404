import React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Typography,
  CssBaseline,
  Divider,
  Fade,
  CircularProgress,
  Button,
} from "@material-ui/core";

import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
export const configJSON = require("./config");
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import Slider, { Settings } from "react-slick";
import { banner } from './assets'
import { PreviousBtnPlay } from "../../../components/src/PreviousBtnPlay.web";
import { NextBtnPlay } from "../../../components/src/NextBtnPlay.web";
import ReadMore from "../../../components/src/ReadMore.web";
import GenericCarousel from "../../../components/src/GenericCarousel.web";
import "./aboutUsSlider.css"

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffb43a",
    },
    background: {
      default: "#121212",
      paper: "#404040"
    },
    text: {
      primary: "#FFF"
    }
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    h1: {
      fontSize: "28px",
      fontWeight: 600,
      textAlign: "center",
      padding: "0 8px",
      "@media (max-width: 600px)": {
      fontSize: "24px",
      }
    },
    h2: {
      textAlign: "center",
      padding: "0 8px",
      fontSize: "24px",
      "@media (max-width: 600px)": {
        fontSize: "20px",
        }
    },
    h4: {
      fontSize: "20px",
      fontWeight: 600,
      "@media (max-width: 600px)": {
        fontSize: "18px"
      }
    },
    body1: {
      fontSize: "16px",
      color: "#d6d6d6",
      "@media (max-width: 600px)": {
        fontSize: "12px"
      }
    },
    body2: {
      fontSize: "16px",
      fontWeight: 600,
      "@media (max-width: 600px)": {
        fontSize: "12px"
      }
    }
  },
});

const investorCarouselSettings: Settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 2,
  draggable: true,
  nextArrow: <NextBtnPlay slidesToShow={4} />,
  prevArrow: <PreviousBtnPlay />,
  responsive: [
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3,
        nextArrow: <NextBtnPlay slidesToShow={3} />,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        nextArrow: <NextBtnPlay slidesToShow={2} />
      }
    }
  ]
}

// Customizable Area End
import AboutUsController, {
  Props,
} from "./AboutUsController.web";

export default class AboutUs extends AboutUsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderAboutSlideComp = () => {
    return (
      <div>
        <img src={banner} style={webStyle.bannerStyle} alt="Banner with cinema picture"/>
        <div className="aboutUsSlider_overlay"/>
        <div className="aboutUsSlider_textDiv">
          <Typography variant="h1">{configJSON.contentSlider}</Typography>
          <Typography variant="h2">{configJSON.contentSmallSlider}</Typography>
        </div>
      </div>
    )
  }

  renderAboutSlideList = () => {

    const settings: Settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 5000,
      draggable: true,
      className: "center",
      centerMode: true,
      dots: true,
      dotsClass: "button__bar",
      arrows: false,
      variableWidth: true,
      centerPadding: "100px",
      responsive: [
        {
          breakpoint: 600,
          settings: {
            centerPadding: "73px",
          },
        },
      ]
    };

    if (this.state.bannerList.length == 1) {
      return (
        <Container>
          <Box style={webStyle.imageWrapper}>
            <img src={this.state.bannerList[0].attributes.banner_image_url} style={webStyle.bannerStyle} alt="Banner with cinema picture" />
            <div className="aboutUsSlider_overlay" />
            <div className="aboutUsSlider_textDiv">
              <Typography variant="h1">{this.state.bannerList[0].attributes.title}</Typography>
              <Typography variant="h2">{this.state.bannerList[0].attributes.description}</Typography>
            </div>
          </Box>
        </Container>
      )
    } 

    return <div className="aboutUsSlider">
      <Slider {...settings}>
        {
          this.state.bannerList.map((item) => (
            <div>
              <img src={item.attributes.banner_image_url} style={webStyle.bannerStyle} alt="Banner with cinema picture" />
              <div className="aboutUsSlider_overlay" />
              <div className="aboutUsSlider_textDiv">
                <Typography variant="h1">{item.attributes.title}</Typography>
                <Typography variant="h2">{item.attributes.description}</Typography>
              </div>
            </div>
          ))
        }
      </Slider>
    </div>
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>

        <CssBaseline />

        <MainWrapper>
          <Box><MainHeaderComp navigation={this.props.navigation} /></Box>

          <Box flex={1}>

            {
              this.state.isLoading ? (
                <Fade in={this.state.isLoading} unmountOnExit>
                  <Box display="flex" justifyContent="center">
                    <CircularProgress className="circular__progress" />
                  </Box>
                </Fade>
              ) : (
                <>
                  <Box className="main__carousel">
                    {this.state.bannerList.length > 0 && this.renderAboutSlideList()}
                  </Box>

                    <Container>

                      {
                        this.state.sections.aboutUs && (
                          <section className="section__wrapper">
                            <Typography variant="h4">{configJSON.headerAboutUs}</Typography>
                            <Typography variant="body1" className="content">
                              <ReadMore content={this.state.sections.aboutUs} charsToDisplay={1500} />
                            </Typography>

                            <Divider className="divider" />
                          </section>
                        )
                      }

                      <section className="section__wrapper investor__section">
                        <Typography variant="h4" className="section__title__action">
                          {configJSON.headerInvestor}
                          <Button className="see__all" data-test-id="btn-investor" onClick={this.handleInvestorBtn}>{configJSON.labelSeeAll}</Button>
                        </Typography>

                        <GenericCarousel settings={investorCarouselSettings}>
                          {
                            this.state.investors.map((investor) => (
                              <Box className="investor__wrapper">
                                <Box className="investor__content">
                                  <img src={investor.attributes.image} className="investor__profile" alt="investor" />
                                  <Typography variant="body2" className="investor__name">{investor.attributes.name}</Typography>
                                </Box>
                              </Box>
                            ))
                          }
                        </GenericCarousel>

                        <Divider className="divider" />

                      </section>

                      <section className="section__wrapper investor__section">
                        <Typography variant="h4" className="section__title__action">
                          {configJSON.headerTeam}
                          <Button className="see__all" data-test-id="btn-team" onClick={this.handleTeamBtn}>{configJSON.labelSeeAll}</Button>
                        </Typography>
                        <Typography variant="body1" className="content">
                          <ReadMore content={this.state.sections.ourTeam} charsToDisplay={1500} />
                        </Typography>

                        <GenericCarousel settings={investorCarouselSettings}>
                          {
                            this.state.team.map((member) => (
                              <Box className="investor__wrapper">
                                <Box className="investor__content">
                                  <img src={member.attributes.image} className="investor__profile" alt="investor" />
                                  <Box className="member__details">
                                    <Typography variant="body2" className="member__name">{member.attributes.name}</Typography>
                                    <Typography variant="body2" className="member__designation">{`(${member.attributes.designation})`}</Typography>
                                  </Box>
                                </Box>
                              </Box>
                            ))
                          }
                        </GenericCarousel>

                        <Divider className="divider" />
                      </section>

                      {
                        this.state.sections.futurePlans && (
                          <section className="section__wrapper">
                            <Typography variant="h4">{configJSON.headerFuturePlan}</Typography>
                            <Typography variant="body1" className="content">
                              <ReadMore content={this.state.sections.futurePlans} charsToDisplay={1500} />
                            </Typography>
                          </section>
                        )
                      }

                    </Container>
                </>
              )}

          </Box>

          <Box><FooterWithEvents /></Box>
        </MainWrapper>

      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  imageWrapper: {
    position: "relative" as const,
    height: "440px",
    width: "100%",
    border: "1px solid #ffb43a",
  },
  mainPaperStyle: {
    minHeight: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column' as const,
    backgroundColor: "#121212"
  },
  ReadMoreLess: {
    fontWeight: 500,
    color: "#ffb43a",
    cursor: 'pointer',
  },
  container:{
    width:"100%",
    maxWidth:"1280px",
    marginLeft:"auto",
    marginRight:"auto"
  },
  lineStyle: {
    height: '1px',
    backgroundColor: '#404040',
    border: 'none',
    width: '90%',
    margin: '34px 0'
  },
  bannerStyle: {
    height: "100%",
    width:"100%",
    position: 'relative' as const,
  },
  boxInv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  gridInv: {
    marginTop: '44px',
    maxWidth: '1440px'
  },
  cardInv: {
    display: 'flex',
    justifyContent: 'space-between', 
  },
  teamGridMainCardStyle: {
    padding: '0 100px',
    marginTop: '24px',
    maxWidth: '1240px',
    width:"100%",
  }
}

const MainWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  "& .circular__progress": {
    margin: "20px auto",
    color: "#ffb43a"
  },
  "& .main__carousel": {
    marginTop: "24px"
  },
  "& .section__wrapper": {
    "& .content": {
      marginTop: "10px",
      "& .main__content": {
        display: "inline",
        "& > p": {
          display: "inline"
        }
      },
      "& .read__more": {
        color: "#ffb43a",
        textTransform: "capitalize",
        lineHeight: 1
      }
    },
    "& .divider": {
      margin: "40px 0",
      height: "1px",
      backgroundColor: "#404040",
    },
    "& .section__title__action": {
      "& .see__all": {
        lineHeight: 1,
        float: "right",
        fontSize: "16px",
        color: "#ffb43a",
        textTransform: "capitalize",
        fontWeight: "lighter",
        cursor: "pointer",
        paddingRight: "unset"
      },
    },
    "&:last-child": {
      "& .divider": {
        display: "none"
      }
    },
    "&:first-child": {
      marginTop: "32px",
    }
  },
  "& .investor__section": {
    "& .investor__wrapper": {
      padding: "16px 14px 0",
      "& .investor__content": {
        position: "relative",
        overflow: "hidden",
        height: "260px",
        borderRadius: "4px",
        "& .investor__profile": {
          width: "100%",
          height: "100%",
        },
        "& .investor__name": {
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          padding: "14px 14px 24px",
          backgroundColor: "rgba(18, 18, 18, 0.5)",
          backdropFilter: "blur(4px)",
          visibility: "hidden",
          transition: "all 0.2s ease-in-out"
        },
        "& .member__details": {
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          padding: "14px 14px 8px",
          backgroundColor: "rgba(18, 18, 18, 0.5)",
          backdropFilter: "blur(4px)",
          visibility: "hidden",
          "& .member__designation": {
            color: "#ffb43a",
          }
        },
        "&:hover": {
          "& .investor__name": {
            visibility: "visible"
          },
          "& .member__details": {
            visibility: "visible"
          }
        }
      },
    },
    "& .slick-next": {
      right: "8px"
    },
    "& .slick-prev": {
      left: "0px"
    }
  },
  "@media (max-width: 900px)": {
    "& .investor__section": {
      "& .investor__wrapper": {
        "& .investor__content": {
          height: "240px !important",
        },
      },
    }
  },
  "@media (max-width: 600px)": {
    "& .section__wrapper": {
      "& .content": {
        marginTop: "8px !important"
      },
      "& .divider": {
        margin: "32px 0 !important"
      }
    },
    "& .investor__section": {
      "& .investor__wrapper": {
        "& .investor__content": {
          height: "200px !important",
        },
      },
    }
  },
  "@media (max-width: 400px)": {
    "& .investor__section": {
      "& .investor__wrapper": {
        "& .investor__content": {
          height: "160px !important",
        },
      },
    }
  }
})

// Customizable Area End
