import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { LocalStorageKeys } from "../../../components/src/enums.web";
import { callApi } from "../../../components/src/Toolkit";
import { getLastPartOfURL } from "../../../components/src/utilities";
import moment from "moment";
import { IEventAdvanchSearch, IEventDetailResponse } from "../../../components/src/interfaces.web";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  eventData: IEventDetailResponse,
  isLoading: boolean
  isLearnMore: boolean;
  isSingleVenue: boolean;
  openSignUp: boolean;
  eventITO: any
  getevent: any;
  showTimeOver: boolean;
  ITOlist: boolean,
  days: number,
  hours: number,
  minutes: number,
  seconds: number,
  isExpired: boolean,
  description: any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EventDetailScreenController extends BlockComponent<
    Props,
    S,
    SS
    > {
    // Customizable Area Start
  getEventDetailApiCall: any
  getTermsAndContionsPage: any
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
    eventData: {} as IEventDetailResponse,
      isLearnMore: false,
      isLoading: true,
      isSingleVenue: false,
      openSignUp: false,
      eventITO: [],
      getevent: '',
      showTimeOver: false,
      ITOlist: false,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      isExpired: true,
      description:""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (responseJson) {
        switch (apiRequestCallId){
          case this.getEventDetailApiCall:
            this.handleEventDetailResponse(responseJson.data);
        break;
        case this.getTermsAndContionsPage:
         this.setState({ description: responseJson[0].description, })
      }
    }
  }
        // Customizable Area End
    }

    // Customizable Area Start

  async componentDidMount() {
    const eventId = this.props.navigation.getParam("id");
    this.setState({ getevent: eventId }, () => {
      this.getEventDetails();
    })
    this.TermsAndContions()
    window.scrollTo(0, 0);
  }

  async handleEventDetailResponse(responseJson: IEventDetailResponse){
    
    const eventBookingData = {
      name: responseJson.attributes.name,
      Address: responseJson.attributes.venues[0].name + ", " + responseJson.attributes.venues[0].user_city_name,
    }
    await setStorageData(LocalStorageKeys.EventBookingData, JSON.stringify(eventBookingData))

    this.startTimer(responseJson.attributes.ito_end_date || "")

    const bookingEndDate = moment(responseJson.attributes.booking_end_date).format("YYYY-MM-DD")
    const bookingEndTime = responseJson.attributes.booking_end_time
    const combinedDateTime = moment(`${bookingEndDate}T${bookingEndTime}`)

    const currentDateTime = moment();
    const differenceInTime = combinedDateTime.diff(currentDateTime, "minute");

    await setStorageData("ITOstate", responseJson.attributes.ito_end_date ? "true" : "false")

    this.setState({
      eventData: responseJson, 
      eventITO: responseJson?.attributes?.ito ? responseJson?.attributes?.ito : null,
      showTimeOver: differenceInTime >= 0 ? false : true,
      ITOlist: !!responseJson.attributes.ito_end_date,
      isLoading: false
    })
  }

  startTimer(ito_end_date: string) {
    let timerID = setInterval(() => {

      let targetDate = new Date(ito_end_date).getTime();

      const currentTime = new Date().getTime();
      const distance = targetDate - currentTime;

      if (distance > 0) {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        this.setState({ days, hours, minutes, seconds, isExpired: false });
      } else {
        this.setState({ isExpired: true })
        clearInterval(timerID);
      }
    }, 1000);
  }

  getEventDetails = async () => {
    const id = getLastPartOfURL()
    this.getEventDetailApiCall = callApi({
      contentType: configJSON.contentTypeApi,
      method: configJSON.GetMethod,
      endPoint: `${configJSON.getAllEventEndPoint}/${id}?location=${await getStorageData(LocalStorageKeys.Location)}`,
      headers: { "token": await getStorageData(LocalStorageKeys.LoginToken) as string || configJSON.guestToken},
    }, runEngine)
  }

  handleOnBookTicket = async() => {

    const userToken = await getStorageData(LocalStorageKeys.LoginToken) as string
    if(!userToken) {
      this.setState({ openSignUp: true });
      return;
    }
    
    if(this.state.showTimeOver) return;
    const getITOstate = await getStorageData("ITOstate")as string;
    getITOstate === "true" || this.state.eventData.attributes.classify === "recurring" ? this.navigateToSelectVenue() : this.handleSingleVenue()
  }

  handleSingleVenue = () => {
    this.setState({ isSingleVenue: true })
  }

  closeSignUp = () => this.setState({ openSignUp: false })

  onSelectRegularBooking = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "SelectTier");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), this.state.eventData.attributes.schedules[0].id);
    this.send(msg);
  }

  onSelectListedTicket = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "ListedTicket");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), this.state.eventData.attributes.schedules[0].id);
    this.send(msg);
  }

  onClosePreference = () => {
    this.setState({ isSingleVenue: false })
  }

  navigateToSelectVenue = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "SelectVenue");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), this.state.eventData.id);
    this.send(msg);
  }

  navigateToTrailer = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EventTrailer");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), this.state.eventData.id);
    this.send(msg);
  }

  TermsAndContions = async () => {
    this.getTermsAndContionsPage = callApi({
      contentType: configJSON.contentTypeApi,
      method: configJSON.GetMethod,
      endPoint: configJSON.TermsAndConditionsURL,
      headers: { "token": await getStorageData(LocalStorageKeys.LoginToken) as string || configJSON.guestToken},
    }, runEngine)
}
    // Customizable Area End
}
